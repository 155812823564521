* {
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family: 'Inter', sans-serif;
}

html {
  background: rgb(16, 21, 34);
}

.body-link {
  color: #fff;
  text-decoration: none;
  transition: 0.3s text-decoration ease;
}

.body-link:hover {
  text-decoration: underline !important;
  transition: 0.3s text-decoration ease;
}

a {
  text-decoration: none !important;
}

#project-btn {
  margin-top: 40px;
  background: rgb(0, 131, 191);
  color: #fff;
  border: 2px solid rgb(0, 131, 191);
}

#project-btn:hover {
  color: #fff;
  border-color: #fff;
  background: transparent;
}

#project-btn:after {
  border-left: 8px solid #fff;
}

/* Portfolio Table Section */
.portfolio-section {
  margin: 0 auto;
  padding: 60px;
  height: auto;
  background: rgb(15, 23, 42);
}

.return-div {
  padding-bottom: 20px;
}

.return-link {
  color: #fff !important;
}

.portfolio-heading {
  padding-bottom: 40px;
}

.portfolio-heading h1 {
  color: #fff;
  font-size: 48px;
}

.portfolio-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #fff;
}

.portfolio-section table {
  width: 100%;
  border-collapse: collapse;
  padding: 20px;
  margin-bottom: 40px;
}

.portfolio-section th {
  padding: 20px;
  color: #fff;
}

.portfolio-section td {
  padding: 20px;
  border-bottom: 1px solid rgb(148, 163, 184);
  color: rgb(148, 163, 184);
}

.portfolio-section th {
  background-color: transparent;
  font-weight: bold;
  text-align: left;
  color: #fff;
}

.portfolio-section tbody tr:nth-child(even) {
  background-color: transparent;
}

.portfolio-section td {
  text-align: left;
  width: 25%;
}

.portfolio-section td a {
  color: #fff;
  text-decoration: none;
}

.portfolio-section td a:hover {
  text-decoration: underline !important;
}

.nav-logo {
  height: 50px;
  width: auto;
  border-radius: 8px;
}

@media screen and (max-width: 1024px) {
  .portfolio-section td {
    text-align: center;
  }

  .portfolio-section th {
    text-align: center;
  }

  .portfolio-section td a {
    font-size: 12px;
    text-align: center;
  }
}

/* Add this CSS to your main stylesheet */
@media screen and (max-width: 600px) {
  table, thead, tbody, th, td, tr {
    display: block;
  }
  
  thead tr {
    display: none;
  }
  
  tr {
    margin-bottom: 2rem;
  }
  
  td {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
  }
  
  td:before {
    content: attr(data-th);
    font-weight: bold;
  }
  .portfolio-section td a {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .portfolio-section {
    padding: 40px;
  }

  .link-label td {
    text-align: center;
  }

  .link-label td a {
    text-align: center;
  }

  .portfolio-section td {
    text-align: right;
    width: 100%;
  }

  .portfolio-section table {
    padding: 0px;
  }
}

/* Table CSS Update Test */
